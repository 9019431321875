import { ref } from "@vue/reactivity";
import { iscode } from "@/utils/app";
export const useList = (api, data) => {
    const search = ref(data);
    search.value = data || search.value
    //  加载状态
    const loading = ref(false);
    //   完成状态
    const finished = ref(false);
    const list = ref([]);
    //   刷新状态
    const refreshing = ref(false);
    const resetLoading=()=>{
        refreshing.value = true
        finished.value = false
        list.value = []
        search.value.page = 1
    }
    //   刷新
    const onRefresh = () => {
        refreshing.value = true
        finished.value = false
        list.value = []
        search.value.page = 1
        onLoad()
    };
    //   加载
    const onLoad = async () => {
        loading.value = true;
        // if(!search.value.out_warehousing_sn) return loading.value = false;
        let result = await api(search.value).then((res) => res.data).catch((error) => error);
        loading.value = false;
        refreshing.value = false
        console.log(result)
        if (iscode(result)) {
            list.value = list.value.concat(result.data)
            search.value.page += 1
            finished.value = result.meta.total <= list.value.length || result.data.length == 0
        } else {
            finished.value = true
        }
    };

    return {
        onRefresh,
        finished,
        refreshing,
        onLoad,
        list,
        search,
        loading,
        resetLoading,
    };
};
